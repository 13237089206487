import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";

class Faq extends Component {

    constructor (props) {
        super (props)
    }

    render (){
        return (
            <div>
                <Breadcrumb title={'Faq'}/>
                                
                {/*Dashboard section*/}
                <section className="faq-section section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="accordion theme-accordion" id="accordionExample">
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link">
                                                    Are your SARMs alcohol based?
                                                </button>
                                            </h5>
                                        </div>

                                        <div id="collapseOne" className="show">
                                            <div className="card-body">
                                                <p> No - all of our SARMs products are glycerol based and do not contain any alcohol whatsoever. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingTwo">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link">
                                                    Are your products purity tested?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseTwo" className="show">
                                            <div className="card-body">
                                                <p> Yes! Our products are tested both in-house and by trusted third parties. All of our SARMs are more than 99% pure on average. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link">
                                                    How long until my order is shipped?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseThree" className="show">
                                            <div className="card-body">
                                                <p> All orders generally ship within 24 - 48 hours. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingFour">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link">
                                                    How is the package shipped?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseFour" className="show">
                                            <div className="card-body">
                                                <p> Our packaging is discrete and safely protected by bubble wrap and sturdy materials. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingFour">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link">
                                                    Do you have a wholesale option?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseFour" className="show">
                                            <div className="card-body">
                                                <p> Yes - we offer wholesale options for bulk orders. Please reach out to us via phone or email for more information. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingFour">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link">
                                                    Do you offer returns or refunds?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseFour" className="show">
                                            <div className="card-body">
                                                <p> Although we will accept returned items, our policy does not allow for any refunds. All sales are final. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default Faq