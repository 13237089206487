import {
    CLEAR_CART,
    ADD_TO_CART,
    REMOVE_FROM_CART,
    INCREMENT_QTY,
    DECREMENT_QTY } from "../constants/ActionTypes";


export default function cartReducer(state = {
    cart: []
}, action) {
    switch (action.type) {
        case ADD_TO_CART:
            const productId = action.product.id
            const selectedSize = action.size

            // Add selected size to product.
            if (selectedSize && action.product.selectedSizes) { 
                for(var i = 0; i < action.qty; i++){
                    action.product.selectedSizes += (selectedSize+",");
                }
            } 

            if (state.cart.findIndex(product => product.id === productId) !== -1) {
                const cart = state.cart.reduce((cartAcc, product) => {

                    if (product.id === productId) {
                        // Add selected size to product.
                        if (selectedSize && product.selectedSizes) { 
                            for(var i = 0; i < action.qty; i++){
                                product.selectedSizes += (selectedSize+",");
                            }
                        } 
                        // This item is already in the cart, so increment it's quantity.
                        cartAcc.push({ ...product, qty: product.qty+action.qty, sum: (product.price)*(product.qty+action.qty) })
                    } else {  
                        // This item doesn't have a match anything in the cart, so just add it.
                        cartAcc.push(product)
                    }
                    return cartAcc
                }, [])
                return { ...state, cart }
            }
 
            return { ...state, cart: [...state.cart, { ...action.product, qty: action.qty, sum: (action.product.price)*action.qty }] }

        case DECREMENT_QTY:
            
            if (state.cart.findIndex(product => product.id === action.productId) !== -1) {
                const cart = state.cart.reduce((cartAcc, product) => {
                    if (product.id === action.productId && product.qty > 1) {
                        cartAcc.push({ ...product, qty: product.qty-1, sum: (product.price)*(product.qty-1) }) // Decrement qty
                    } else {
                        cartAcc.push(product)
                    }
                    return cartAcc
                }, [])
                return { ...state, cart }
            }
            return { ...state, cart: [...state.cart, { ...action.product, qty: action.qty, sum: action.product.price*action.qty }] }

        case REMOVE_FROM_CART:
            // If the item being removed has selectedSizes, make sure to reset the string to a default value of "".
            if (action.product_id.selectedSizes) {
                if (state.cart.findIndex(item => item.id === action.product_id.id) !== -1) {
                    const cart = state.cart.reduce((_cartAcc, item) => {
                        if (item.selectedSizes && (item.id === action.product_id.id)) { 
                            item.selectedSizes = ""; 
                            action.product_id.selectedSizes = "";
                        }
                    }, [])
                } 
            } 

            return {
                cart: state.cart.filter(item => item.id !== action.product_id.id)
            }

        case CLEAR_CART:
            return {
                cart: []
            }
        
        default:
    }
    return state;
}
