import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './index.scss';

// Import custom components
import store from './store';
import { getAllProducts } from './actions'
import Landing from './components/landing'

// Collection Page
import CollectionNoSidebar from "./components/collection/collection-no-sidebar";

// Product Page
import NoSideBar from "./components/products/no-sidebar";

// Features
import Layout from './components/app'
import Cart from './components/cart'
import checkOut from './components/checkout'
import orderSuccess from './components/checkout/success-page'

// Extra Pages
import aboutUs from './components/pages/about-us'
import Faq from './components/pages/faq'
import Disclaimer from './components/pages/disclaimer'
// import PageNotFound from './components/pages/404'

class Root extends React.Component {

    render() {
        store.dispatch(getAllProducts());

        return(
        	<Provider store={store}>
				<HashRouter basename={'/'} >
						<Switch>
                            <Route exact path={`${process.env.PUBLIC_URL}/`} component={Landing}/>
                            <Layout>
								{/*Route For Product Collection */}
								<Route path={`${process.env.PUBLIC_URL}/shop`} component={CollectionNoSidebar}/>

								{/*Routes For Single Product*/}
								<Route path={`${process.env.PUBLIC_URL}/product/:id`} component={NoSideBar}/>							

								{/*Routes For custom Features*/}
								<Route path={`${process.env.PUBLIC_URL}/cart`} component={Cart}/>
								<Route path={`${process.env.PUBLIC_URL}/checkout`} component={checkOut}/>
								<Route path={`${process.env.PUBLIC_URL}/order-success`} component={orderSuccess}/>

								{/*Routes For Extra Pages*/}
                                <Route path={`${process.env.PUBLIC_URL}/about`} component={aboutUs}/>
                                <Route path={`${process.env.PUBLIC_URL}/faq`} component={Faq}/>
								<Route path={`${process.env.PUBLIC_URL}/disclaimer`} component={Disclaimer}/>
                                {/* <Route component={PageNotFound}/> */}
                            </Layout>
                        </Switch>
				</HashRouter>
			</Provider>
    	);
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));


