import React, {Component} from 'react';
import {ToastContainer} from 'react-toastify';

// Custom Components
import HeaderOne from './common/headers/header-one';
import FooterOne from "./common/footers/footer-one";

class App extends Component {

    render() {
        return (
            <div>
                <HeaderOne logoName={'srt-logo.png'}/>
                {this.props.children}
                <FooterOne logoName={'srt-logo.png'}/>
                <ToastContainer/>
            </div>
        );
    }
}

export default App;
