import React, {Component} from 'react';
import {Link} from 'react-router-dom'

class DetailsWithPrice extends Component {

    constructor (props) {
        super (props)
        this.state = {
            open: false,
            quantity: 1,
            stock: '',
            size: null,
            nav3: null
        }
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    componentDidMount() {
        this.setState({
            nav3: this.slider3
        });

        // Check for item stock
        if(this.props.item.stock >= this.state.quantity) {
            this.setState({stock: 'In Stock'})
        }else{
            this.setState({stock: 'Out of Stock'})
            document.getElementById('addToCartBtn').hidden = true;
            document.getElementById('buyNowBtn').hidden = true;
        }

        // Check for item size
        if(this.props.item.size) {
            document.getElementById('addToCartBtn').hidden = true;
            document.getElementById('buyNowBtn').hidden = true;
        }
    }

    minusQty = () => {
        if(this.state.quantity > 1) {
            this.setState({stock: 'In Stock'})
            this.setState({quantity: this.state.quantity - 1})
        }
    }

    plusQty = () => {
        if(this.props.item.stock >= this.state.quantity) {
            this.setState({quantity: this.state.quantity+1})
        }else{
            this.setState({stock: 'Out of Stock'})
        }
    }

    changeQty = (e) => {
        this.setState({ quantity: parseInt(e.target.value) })
    }

    sizeSelected = (e) => {
        this.setState({ size: e.target.value })
        document.getElementById('addToCartBtn').hidden = false;
        document.getElementById('buyNowBtn').hidden = false;
    }

    render (){
        const {symbol, item, addToCartClicked, BuynowClicked} = this.props

        return (
            <div className="col-lg-6 rtl-text">
                <div className="product-right">
                    <h2> {item.name} </h2>
                    <h3>{symbol}{item.price} </h3>
                    <div className="product-description border-product">
                        {item.size?
                            <div>
                                <h6 className="product-title size-text">select size</h6>
                                <div className="size-box">
                                    <ul>
                                        {item.size.map((size, i) => {
                                            return <li key={i}><input onChange={this.sizeSelected} type="radio" id="sizeSelector" name="sizeSelector" value={size}></input><br/><label for={i}>{size}</label></li>
                                        })}
                                    </ul>
                                </div>
                        </div>:''}
                        <span className="instock-cls">{this.state.stock}</span>
                        <h6 className="product-title">quantity</h6>
                        <div className="qty-box">
                            <div className="input-group">
                                  <span className="input-group-prepend">
                                    <button type="button" className="btn quantity-left-minus" onClick={this.minusQty} data-type="minus" data-field="">
                                     <i className="fa fa-angle-left"></i>
                                    </button>
                                  </span>
                                <input type="text" name="quantity" value={this.state.quantity} onChange={this.changeQty} className="form-control input-number" />
                                <span className="input-group-prepend">
                                <button type="button" className="btn quantity-right-plus" onClick={this.plusQty} data-type="plus" data-field="">
                                <i className="fa fa-angle-right"></i>
                                </button>
                               </span>
                            </div>
                        </div>
                    </div>
                    <div className="product-buttons" >
                        <a id="addToCartBtn" className="btn btn-solid" onClick={() => addToCartClicked(item, this.state.quantity, this.state.size)}>add to cart</a>
                        <Link to={`/checkout`} id="buyNowBtn" className="btn btn-solid" onClick={() => BuynowClicked(item, this.state.quantity, this.state.size)} >buy now</Link>
                    </div>
                    <div className="border-product">
                        <h6 className="product-title">product details</h6>
                        <p>{item.shortDetails}</p>
                    </div>
                </div>
            </div>
        )
    }
}


export default DetailsWithPrice;