import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";

class aboutUs extends Component {

    constructor (props) {
        super (props)
    }

    render (){
        return (
            <div>
                <Breadcrumb title={'About Us'}/>

                {/*about section*/}
                <section className="about-page  section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>This company was formed by three friends that wanted to make a difference in the supplement industry.</h4>
                                <p> 
                                    They all have shared a love of sports (MMA, baseball, basketball, and wrestling to name a few) and the gym since their teenage years.  
                                    The owners want to bring powerful, no nonsense, effective supplements to people from all walks of life.  
                                    The three owners make an extremely well rounded team, and all come from different backgrounds.  
                                    One is a competitive bodybuilder and union worker, another is a successful, self employed, avid gym member who lives the health and fitness life to a T, while the third is a family man, amateur muay thai fighter, who has been active in sports since the age of 8. 
                                    Together, we feel that we can relate and offer supplements that will give everyone, from the recreational gym goer to the competitive athlete, the supplements they need and want to obtain their goals.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default aboutUs