import React, {Component} from 'react';
import '../common/index.scss';

// import custom Components
import ProductListing from './common/product-listing'
import Breadcrumb from "../common/breadcrumb";
import FilterBar from "./common/filter-bar";

class CollectionNoSideBar extends Component {

    state = {
        layoutColumns:3
    }

    LayoutViewClicked(columns) {
        this.setState({
            layoutColumns:columns
        })
    }

    render(){
        window.scrollTo(0, 0);

        return (
            <div>
                <Breadcrumb title={'PRODUCTS'} />

                {/*Section Start*/}
                <section className="section-b-space">
                    <div className="collection-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="collection-content col">
                                    <div className="page-main-content">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="top-banner-wrapper">
                                                        <a href="#/shop"><img src={`/assets/images/banner-1.jpeg`}
                                                                         className="img-fluid" alt=""/></a>
                                                        <div className="top-banner-content small-section">
                                                            <h4>Selective androgen receptor modulator (SARM)</h4>
                                                            <h5>SARMs are a novel class of androgen receptor ligands.</h5>
                                                            <p>They are intended to have the same kind of effects as androgenic drugs but be much more selective in their action, allowing them to be used for more uses than the relatively limited legitimate uses of anabolic steroids. SARMs represent a new generation of tissue-selective androgens with an as yet unrealized potential to treat several diseases.</p>
                                                        </div>
                                                    </div>
                                                    <div className="collection-product-wrapper">
                                                        <div className="product-top-filter">
                                                            <div className="container-fluid p-0">
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <FilterBar onLayoutViewClicked={(colmuns) => this.LayoutViewClicked(colmuns)}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="product-wrapper-grid">
                                                            <div className="container-fluid">
                                                                <div className="row">
                                                                    <ProductListing colSize={this.state.layoutColumns}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*Section End*/}
            </div>
        )
    }
}

export default CollectionNoSideBar;