import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import PaypalExpressBtn from 'react-paypal-express-checkout';
import SimpleReactValidator from 'simple-react-validator';

import Breadcrumb from "../common/breadcrumb";
import {getCartTotal} from "../../services";
import promoData from "../../api/promos.json";

class checkOut extends Component {

    constructor (props) {
        super (props)

        this.state = {
            taxRate: 0.0865, // NYS sales tax rate
            taxCost: 0.00,
            shippingCost: 0.00,
            subtotal: 0.00,
            orderTotal: 0.00,
            discount: 0.00,
            discountValueInUSD: 0.00,
            promo: '',
            promoApplied: false,
            payment: 'paypal',
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            country: 'US',
            address: '',
            city: '',
            state: '',
            zipcode: ''
        }
        this.validator = new SimpleReactValidator();
    }

    setStateFromInput = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
    }

    checkhandle(value) {
        this.setState({
            payment: value
        })
    }

    checkPromoCode() {
      // Check if the inputted promo code exists.
      // If the promo code is valid, apply the discount and lock the UI.
      // Else, show an invalid promo code error.
      if (!this.state.promoApplied) {
        if (promoData[this.state.promo]) {
          var discount = promoData[this.state.promo];
          this.setState({ discount: discount, promoApplied: true });
        } else {
          console.log("Invalid promo code.")
        }
      }
    }

    StripeClick = () => {

        // if (this.validator.allValid()) {
        //     var handler = (window).StripeCheckout.configure({
        //         //key: 'pk_test_51Hop52CifS6zOb6834OpCfTRB4cW6cr2zx3eVXVuwq1u9ZTnRI8o0PsxwU9SfBvX5TNgZInm4YXU7URv4huPryg200EeXjmuRj',
        //         key: 'pk_live_51Hop52CifS6zOb68d944X9w72K1CPPCaz6F92OJtvPDimHFY5fNnt4K68isPkvnL9hxqdBpxlgpIvDecLQsTAZlf00F8Ok9lqe',
        //         locale: 'auto',
        //         token: (token: any) => {
        //             this.props.history.push({
        //                 pathname: '/order-success',
        //                 state: { payment: token, items: this.props.cartItems, symbol: this.props.symbol, checkoutState: this.state }
        //             })
        //         }
        //       });
        //       handler.open({
        //         name: 'SRT Labs',
        //         description: 'Quality SARMs You Can Trust',
        //         amount: this.amount * 100
        //       })
        // } else {
        //   this.validator.showMessages();
        //   // rerender to show messages for the first time
        //   this.forceUpdate();
        // }
    }

    render (){
        const {cartItems, symbol, cartTotal} = this.props;

        // Calculate sub total.
        if (!this.state.promoApplied) {
          this.state.subtotal = parseFloat((cartTotal).toFixed(2));
        } else {
          // Promo is applied, so calculate the discount.
          var discountPercentage = this.state.discount / 100.0;
          this.state.discountValueInUSD = parseFloat((cartTotal).toFixed(2)) * discountPercentage;
          this.state.subtotal = parseFloat((cartTotal).toFixed(2)) - this.state.discountValueInUSD;
        }

        // Calculate tax cost.
        this.state.taxCost = parseFloat((this.state.subtotal * this.state.taxRate).toFixed(2));

        // Calculate shipping costs.
        this.state.shippingCost = parseFloat((15.00).toFixed(2));

        // Calculate order total;
        this.state.orderTotal = parseFloat((this.state.subtotal + this.state.taxCost + this.state.shippingCost)).toFixed(2);

        // Construct itemized order list for Paypal.
        var paypalItems = [];
        cartItems.forEach(element => {
            var elementName = element.name;
            if (element.selectedSizes) {
                var sizeString = " - Sizes:"+element.selectedSizes;
                elementName += sizeString;
            }
            var i = {
                "name": elementName,
                "sku": element.id,
                "price": element.price,
                "currency": "USD",
                "quantity": element.qty
            }
            paypalItems.push(i);
        });

        if (this.state.promoApplied) {
          // Add a promo item with negative value to the paypal list,
          // otherwise PP will complain that the cart items don't add up to the discounted total.
          var discountPrice = this.state.discountValueInUSD * -1;
          var promoItem = {
            "name": this.state.promo,
            "sku": this.state.promo,
            "price": discountPrice,
            "currency": "USD",
            "quantity": 1
          }
          paypalItems.push(promoItem);
        }

        // Setup Paypal options.
        const paypalOptions = {
          "transactions": [
            {
              "amount": {
                "total": String(this.state.orderTotal),
                "currency": "USD",
                "details":{
                    "subtotal": String(this.state.subtotal),
                    "shipping": String(this.state.shippingCost),
                    "tax": String(this.state.taxCost)
                }
              },
              "description": "Web transaction from srtbio.com.",
              "item_list": {
                "items": paypalItems
              }
            }
          ]
        }

        // Paypal Integration
        const onSuccess = (payment) => {
          this.props.history.push({
            pathname: '/order-success',
            state: { payment: payment, items: cartItems, symbol: symbol, checkoutState: this.state }
          })
        }

        const onCancel = (data) => {
          console.log('The payment was cancelled!', data);
        }

        const onError = (err) => {
          console.log("Error!", err);
        }

        const client = {
          sandbox:    'AdUfJ_mKIlpuSKKcLlzwppgRgCr0coBvnYGQWdfJPh096lrVbeyAg9kmZvMETf-dTr8JhiS81U1wQEU9',
          production: 'AcMr9KcQVhbWFv0KWh_KS7CRL8BBn9iEAaN1QSRW9-KgSE2LGN6_AWDh1VbN5OFngUnYD-vFVwNudzKV',
        }

        return (
            <div>

                {/*SEO Support*/}
                <Helmet>
                    <title>SRT | Checkout</title>
                    <meta name="description" content="SRT – Quality SARMs You Can Trust" />
                </Helmet>
                {/*SEO Support End */}

                <Breadcrumb  title={'Checkout'}/>

                <section className="section-b-space">
                    <div className="container padding-cls">
                        <div className="checkout-page">
                            <div className="checkout-form">
                                <form>
                                    <div className="checkout row">
                                        <div className="col-lg-6 col-sm-12 col-xs-12">
                                            <div className="checkout-title">
                                                <h3>Billing Details</h3>
                                            </div>
                                            <div className="row check-out">
                                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                    <div className="field-label">First Name</div>
                                                    <input type="text" name="first_name" value={this.state.first_name} onChange={this.setStateFromInput} />
                                                    {this.validator.message('first_name', this.state.first_name, 'required|alpha')}
                                                </div>
                                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                    <div className="field-label">Last Name</div>
                                                    <input type="text" name="last_name" value={this.state.last_name} onChange={this.setStateFromInput} />
                                                    {this.validator.message('last_name', this.state.last_name, 'required|alpha')}
                                                </div>
                                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                    <div className="field-label">Phone</div>
                                                    <input type="text" name="phone"  value={this.state.phone} onChange={this.setStateFromInput} />
                                                    {this.validator.message('phone', this.state.phone, 'required|phone')}
                                                </div>
                                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                    <div className="field-label">Email Address</div>
                                                    <input type="text" name="email" value={this.state.email} onChange={this.setStateFromInput} />
                                                    {this.validator.message('email', this.state.email, 'required|email')}
                                                </div>
                                                <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                                    <div className="field-label">Country</div>
                                                    <select name="country" value={this.state.country} onChange={this.setStateFromInput}>
                                                        <option>United States</option>
                                                    </select>
                                                    {this.validator.message('country', this.state.country, 'required')}
                                                </div>
                                                <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                                    <div className="field-label">Address</div>
                                                    <input type="text" name="address" value={this.state.address} onChange={this.setStateFromInput} placeholder="Street address" />
                                                    {this.validator.message('address', this.state.address, 'required|min:10|max:120')}
                                                </div>
                                                <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                                    <div className="field-label">Town/City</div>
                                                    <input type="text" name="city" value={this.state.city} onChange={this.setStateFromInput} />
                                                    {this.validator.message('city', this.state.city, 'required|alpha_space')}
                                                </div>
                                                <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                                    <div className="field-label">State</div>
                                                    <input type="text" name="state" value={this.state.state} onChange={this.setStateFromInput} />
                                                    {this.validator.message('state', this.state.state, 'required|alpha')}
                                                </div>
                                                <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                                    <div className="field-label">Zip Code</div>
                                                    <input type="text" name="zipcode" value={this.state.zipcode} onChange={this.setStateFromInput} />
                                                    {this.validator.message('zipcode', this.state.zipcode, 'required|integer')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-12 col-xs-12">
                                            <div className="checkout-details">
                                                <div className="order-box">
                                                    <div className="title-box">
                                                        <div>Product <span> Total</span></div>
                                                    </div>
                                                    <ul className="qty">
                                                        {cartItems.map((item, index) => {
                                                            return <li key={index}>{item.name} × {item.qty} <span>{symbol} {item.sum}</span></li> })
                                                        }
                                                    </ul>
                                                    <ul className="sub-total">
                                                        <li>Subtotal <span className="count">{symbol}{parseFloat(this.state.subtotal).toFixed(2)}</span></li>
                                                        <li>Tax <span className="count">{symbol}{parseFloat(this.state.taxCost).toFixed(2)}</span></li>
                                                        <li>Standard Shipping <span className="count">{symbol}{parseFloat(this.state.shippingCost).toFixed(2)}</span></li>
                                                        <li>Promo Code <br></br><br></br>
                                                            <div className="promo">
                                                                <input type="text" name="promo" value={this.state.promo} onChange={this.setStateFromInput} disabled={this.state.promoApplied}/>
                                                                <br></br><br></br>
                                                                <button type="button" className="btn-solid btn" onClick={() => this.checkPromoCode()} disabled={this.state.promoApplied}>Apply</button>
                                                            </div>
                                                        </li>
                                                    </ul>

                                                    <ul className="total">
                                                        {(this.state.promoApplied)? <p>Discount applied! {this.state.discount}% OFF</p> : ''}
                                                        <li>Total <span className="count">{symbol}{parseFloat(this.state.orderTotal).toFixed(2)}</span></li>
                                                    </ul>
                                                </div>

                                                <div className="payment-box">
                                                    <div className="upper-box">
                                                        <div className="payment-options">
                                                            <ul>
                                                                {/* <li>
                                                                    <div className="radio-option stripe">
                                                                        <input type="radio" name="payment-group" id="payment-2" defaultChecked={true} onClick={() => this.checkhandle('stripe')} />
                                                                        <label htmlFor="payment-2">Stripe</label>
                                                                    </div>
                                                                </li> */}
                                                                <li>
                                                                    <div className="radio-option paypal">
                                                                        <input type="radio" name="payment-group" id="payment-1" defaultChecked={true} onClick={() => this.checkhandle('paypal')} />
                                                                            <label htmlFor="payment-1">PayPal<span className="image"><img src={`/assets/images/paypal.png`} alt=""/></span></label>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    {(this.state.orderTotal !== 0.00)?
                                                    <div className="text-right">
                                                        {(this.state.payment === 'stripe')? <button type="button" className="btn-solid btn" onClick={() => this.StripeClick()} >Place Order</button>:
                                                         <PaypalExpressBtn env={'production'} client={client} currency={'USD'} total={parseFloat(this.state.orderTotal)} onError={onError} onSuccess={onSuccess} onCancel={onCancel} paymentOptions={paypalOptions}/>}
                                                    </div>
                                                    : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    cartTotal: getCartTotal(state.cartList.cart)
})

export default connect(
    mapStateToProps,
)(checkOut)
