import React, { Component } from 'react';
import Popup from "./common/popup.jsx";
import FooterOne from "./common/footers/footer-one";

import './landing.scss';

class Landing extends Component {

    constructor(props) {
        super(props)
        this.state = {
            toggle: false,
            showPopup: true
        }
    }

    componentDidMount() {
        setTimeout(function () {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000);
    }

    toggleMenu = () => {
        this.setState({
            toggle: !this.state.toggle
        })
    }

    togglePopup() {
        this.setState({
          showPopup: !this.state.showPopup
        });
    }

    render() {
        window.scrollTo(0, 0);

        return (
            <div className="landing-page">
                <header id="sticky" className="sticky">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col p-0">
                                <div className="top-header">
                                    <div className="logo pl-2">
                                        <a className="navbar-brand" href="#"><img
                                            src={`/assets/images/landing-page/header/1.png`}
                                            alt="logo" /></a>
                                    </div>
                                    <div className="mx-auto" id="nav"></div>
                                    <div>
                                        <form target="_blank" className="form-inline my-lg-0"
                                            action={`#/shop`}>
                                            <a  href={`#/shop`}
                                            className="btn my-sm-0 purchase-btn">buy now</a>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                {/*Home section*/}
                <section className="main-img responsive-img pt-0" id="img-bg">
                    <div className="container-fluid">
                        <div className="main-contain">
                            <div>
                                <h1 className="m-0">SRT<span>LABS</span></h1>
                                <h3 className="m-0">Quality <span>SARMs</span> You Can Trust</h3>
                            </div>
                            {this.state.showPopup ?
                                <Popup
                                text='FDA DISCLAIMER'
                                closePopup={this.togglePopup.bind(this)}
                                />
                                : null
                            }
                        </div>
                        <div className="home-decor"></div>
                    </div>
                </section>

                {/*Features section*/}
                <section id="feature" className="section-lr section-b-space feature-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="feature text-center">
                                    <div>
                                        <h5 className="title-landing">Why Choose SRT?</h5>
                                        <p className="pb-3">It is important to choose a 
                                        US based supplier that delivers reliable purity & quality.
                                        That's where we come in.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row feature_row">
                            <div className="col-xl-3 col-md-6 ">
                                <div className="feature-box">
                                    <div className="feature-image">
                                        <img className="img-fluid lazyload"
                                            src={`/assets/images/landing-page/main-1.png`} alt="img" />
                                    </div>
                                    <div className="feature-content">
                                        <ul className="color-variant">
                                            <li className="red"></li>
                                        </ul>
                                        <h6>Lab Tested</h6>
                                    </div>
                                    <p className="para">Our SARMs products are subject to rigorous testing procedures both in-house and by trusted third parties.</p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 ">
                                <div className="feature-box">
                                    <div className="feature-image">
                                        <img className="img-fluid lazyload"
                                            src={`/assets/images/landing-page/main-2.png`}
                                            alt="img" />
                                    </div>
                                    <div className="feature-content">
                                        <ul className="color-variant">
                                            <li className="red"></li>
                                        </ul>
                                        <h6>Highest Purity</h6>
                                    </div>
                                    <p className="para">No other product compares to the level of purity that we supply in our products. All of our SARMs average &gt;99% purity.</p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <div className="feature-box">
                                    <div className="feature-image">
                                        <img className="img-fluid lazyload"
                                            src={`/assets/images/landing-page/main-3.png`}
                                            alt="img" />
                                    </div>
                                    <div className="feature-content">
                                        <ul className="color-variant">
                                            <li className="red"></li>
                                        </ul>
                                        <h6>Sustainably Sourced</h6>
                                    </div>
                                    <p className="para">Our SARMs are carefully selected and must pass a comprehensive QA process before they are made available for purchase.</p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <div className="feature-box">
                                    <div className="feature-image">
                                        <img className="img-fluid lazyload"
                                            src={`/assets/images/landing-page/main-4.png`} alt="img" />
                                    </div>
                                    <div className="feature-content">
                                        <ul className="color-variant">
                                            <li className="red"></li>
                                        </ul>
                                        <h6>Reputable Supplier</h6>
                                    </div>
                                    <p className="para">You can rest assured that we are one of the best and most reliable US based suppliers in the country.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*Footer section*/}
                <section id="footer" className="section-b-space grey-bg footer">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="footer-section">
                                    <div>
                                        <ul className="rate-section">
                                            <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                            <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                            <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                            <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                            <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                        </ul>
                                        <h2>The Best rated & <br />
                                            most effective SARMs on the planet 🚀</h2>
                                        <a  href={`#/shop`}
                                            className="btn btn-primary">buy now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <FooterOne/>

                {/*Tap To Top*/}
                <div className="tap-top">
                    <div>
                        <i className="fa fa-angle-double-up"></i>
                    </div>
                </div>

            </div>
        );
    }
}

export default Landing;
