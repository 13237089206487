import React, {Component} from 'react';
import { connect } from 'react-redux'
import { clearCart } from '../../actions';
import {getCartTotal} from "../../services";

class orderSuccess extends Component {

    constructor (props) {
        super (props)
    }

    componentDidMount() {
        this.props.clearCart();
    }

    componentWillUnmount() {
        window.location.reload(); // Weird fix for selectedSizes: Must reload the window to update the local cache and product data. Otherrwise, selectedSizes state will persist after removing and readding the product.
    }

    render (){
        window.scrollTo(0, 0);

        const {payment, items, symbol, checkoutState} = this.props.location.state;
        var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        var current = new Date();
        var next7days = new Date(Date.now() + 7 * 86400000);
        let CheckDate = current.toLocaleDateString("en-US", options).toString()
        let deliveryDate = next7days.toLocaleDateString("en-US", options).toString()

        return (
            (payment)?
            <div>
                <section className="section-b-space light-layout">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="success-text">
                                    <i className="fa fa-check-circle" aria-hidden="true"></i>
                                    <h2>thank you</h2>
                                    <p>Your Order Has Been Placed Successfully</p>
                                    <p>Transaction ID: {(payment.paymentID)?payment.paymentID:payment.id}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="product-order">
                                    <h3>your order details</h3>
                                    {items.map((item, index) => {
                                    return <div className="row product-order-detail" key={index}>
                                                <div className="col-3">
                                                    <img src={item.variants?
                                                        item.variants[0].images
                                                        :item.pictures[0]} alt="" className="img-fluid" />
                                                </div>
                                                <div className="col-3 order_detail">
                                                    <div>
                                                        <h4>product name</h4>
                                                        <h5>{item.name}</h5>
                                                    </div>
                                                </div>
                                                {(item.selectedSizes)?
                                                <div className="col-3 order_detail">
                                                    <div>
                                                        <h4>Sizes</h4>
                                                        <h5>{item.selectedSizes}</h5>
                                                    </div>
                                                </div>
                                                :
                                                <div></div>}
                                                <div className="col-3 order_detail">
                                                    <div>
                                                        <h4>quantity</h4>
                                                        <h5>{item.qty}</h5>
                                                    </div>
                                                </div>
                                                <div className="col-3 order_detail">
                                                    <div>
                                                        <h4>price</h4>
                                                        <h5>{symbol}{item.sum}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                    })}
                                    <div className="total-sec">
                                        <ul>
                                            <li>subtotal <span>{symbol}{parseFloat(checkoutState.subtotal).toFixed(2)}</span></li>
                                            <li>shipping <span>{symbol}{parseFloat(checkoutState.shippingCost).toFixed(2)}</span></li>
                                            <li>tax <span>{symbol}{parseFloat(checkoutState.taxCost).toFixed(2)}</span></li>
                                        </ul>
                                    </div>
                                    <div className="final-total">
                                        <h3>total <span>{symbol}{parseFloat(checkoutState.orderTotal).toFixed(2)}</span></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row order-success-sec">
                                    <div className="col-sm-6">
                                        <h4>summary</h4>
                                        <ul className="order-detail">
                                            {(payment.paymentID)?
                                                <div>
                                            <li>payer ID: {payment.payerID}</li>
                                            <li>payment ID: {payment.paymentID}</li>
                                            <li>payment Token: {payment.paymentToken}</li></div>
                                                :
                                            <li>Order ID: {payment.id}</li> }

                                            <li>Order Date: {CheckDate}</li>
                                            <li>Order Total: {symbol}{checkoutState.orderTotal}</li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-6">
                                        <h4>shipping address</h4>
                                        <ul className="order-detail">
                                            <li>{checkoutState.first_name} {checkoutState.last_name}</li>
                                            <li>{checkoutState.address}</li>
                                            <li>{checkoutState.city}, {checkoutState.state} {checkoutState.zipcode}</li>
                                            <li>Phone: {checkoutState.phone}</li>
                                        </ul>
                                    </div>

                                    <div className="col-sm-12 payment-mode"></div>
                                    <div className="col-md-12">
                                        <div className="delivery-sec">
                                            <h3>expected date of delivery</h3>
                                            <h2>{deliveryDate}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            :
            <section className="p-0">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="error-section">
                                <h1>404</h1>
                                <h2>page not found</h2>
                                <a href="index.html" className="btn btn-solid">back to home</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    total: getCartTotal(state.cartList.cart)
})

export default connect(
    mapStateToProps,
    {clearCart}
)(orderSuccess)