import shop from '../api/shop'
import * as types from '../constants/ActionTypes'
import { toast  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export const fetchProductsBegin = () => ({
    type: types.FETCH_PRODUCTS_BEGIN
});

export const receiveProducts = products => ({
    type: types.RECEIVE_PRODUCTS,
    products
})

export const getAllProducts = () => dispatch => {
    dispatch(fetchProductsBegin());
    shop.getProducts(products => {
        dispatch(receiveProducts(products));
        return products;
    })
}
export const fetchSingleProduct = productId => ({
    type: types.FETCH_SINGLE_PRODUCT,
    productId
})

// Cart
export const clearCart = () => ({
    type: types.CLEAR_CART
});
export const addToCart = (product, qty, size) => (dispatch) => {
    toast.success("Item Added to Cart");
    dispatch(addToCartUnsafe(product, qty, size))
}
export const addToCartUnsafe = (product, qty, size) => ({
    type: types.ADD_TO_CART,
    product,
    qty,
    size
});
export const removeFromCart = product_id => (dispatch) => {
    // toast.error("Item Removed from Cart");
    dispatch({
        type: types.REMOVE_FROM_CART,
        product_id
    })
    window.location.reload(); // Weird fix for selectedSizes: Must reload the window to update the local cache and product data. Otherrwise, selectedSizes state will persist after removing and readding the product.
};
export const incrementQty = (product,qty) => (dispatch) => {
    //toast.success("Item Added to Cart");
    dispatch(addToCartUnsafe(product, qty))
}
export const decrementQty = productId => (dispatch) => {
    //toast.warn("Item Decrement Qty to Cart");

    dispatch({
    type: types.DECREMENT_QTY,
    productId})
};

// Filters
export const filterBrand = (brand) => ({
    type: types.FILTER_BRAND,
    brand
});
export const filterColor = (color) => ({
    type: types.FILTER_COLOR,
    color
});
export const filterPrice = (value) => ({
    type: types.FILTER_PRICE,
    value
});
export const filterSort = (sort_by) => ({
    type: types.SORT_BY,
    sort_by
});


// Currency
export const changeCurrency = (symbol) => ({
    type: types.CHANGE_CURRENCY,
    symbol
});

