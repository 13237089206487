import React from 'react';
import '../landing.scss';

class Popup extends React.Component {
  render() {
    return (
      <div className='popup'>
        <div className='popup-inner'>
            <br/>
            <h3 className='popup-title'>{this.props.text}</h3>
            <p className='popup-text'>The statements made regarding these products have not been evaluated by the Food and Drug Administration. <br/>
                                    The efficacy of these products has not been confirmed by FDA-approved research.<br></br> 
                                    These products are not intended to diagnose, treat, cure or prevent any disease. <br/><br/>
                                    All information presented here is not meant as a substitute for or alternative to information from healthcare practitioners. 
                                    Please consult your healthcare professional about potential interactions or other possible complications before using any product. 
                                    The Federal Food, Drug and Cosmetic Act requires this notice.
            </p>
            <br/>
            <button className='btn btn-primary'  onClick={this.props.closePopup}>Accept &amp; Continue</button>
        </div>
      </div>
    );
  }
}

export default Popup;